import * as types from '../mutation-types'

// state
export const state = {
  added_favorites_id: '',
}

// getters
export const getters = {
  added_favorites_id: state => state.added_favorites_id,
}

// mutations
export const mutations = {
  [types.ADD_FAVORITES_ID] (state, { added_favorites_id }) {
    state.added_favorites_id = added_favorites_id
  },
}

// actions
export const actions = {
  add_favorites_id ({ commit }, payload) {
    commit(types.ADD_FAVORITES_ID, payload)
  },
}
