<template>
  <el-card shadow="always">
    <div v-if="title" slot="header" class="clearfix">
      <span>{{ title }}</span>
    </div>

    <div>
      <slot/>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'ElCard',

  props: {
    title: { type: String, default: null }
  }
}
</script>
<style>
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }
  .clearfix {
    color: #fff;
    background-color: rgb(33, 150, 243);
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 480px;
  }
</style>
