<template>
  <f7-page>
    <f7-navbar :title="$t('resume upload')" :back-link="$t('back')"></f7-navbar>
    <!-- <f7-list no-hairlines-md form class=""> -->
    <f7-list inline-labels no-hairlines-md form class="">
        <f7-list-item>
            <el-upload ref="upload"
                class="upload-demo"
                action=""
                :on-remove="handleRemove"
                :on-change="handleChange"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="form.fileList"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">{{ $t('select file') }}</el-button>
                <div slot="tip" class="el-upload__tip">500kb以下のEXCELファイルのみUPLOAD可</div>
            </el-upload>
        </f7-list-item>
        <f7-list-button v-if="platformId=='ios'"
            type="submit"
            :loading="form.busy"
            v-on:click.stop.prevent="UploadEngineerResume">
            {{ $t('confirm') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large v-on:click.stop.prevent="UploadEngineerResume">
                {{ $t('confirm') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import Form from 'vform'
import moment from 'moment';

export default {
  name: 'UpdateEngineerResume',
  metaInfo () {
    return { title: this.$t('resume upload') }
  },
  data() {
    return {
      form: new Form({
          id: '',
          fileList: [],
          resume_ServerFileName: '',
      }),
      platformId: 'ios',
    };
  },
  created() {
    let options = JSON.parse(this.$f7route.params.options)
    this.form.id = options.engineer_id
  },
  mounted() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  methods: {
    async UploadEngineerResume () {
      await this.form.patch('/api/admin/engineer/upload_engineer_resume')
      .then(response => {
        this.status = response.data.status
        //clear the ruleForm errors
        this.form.clear()
        this.form.reset()
        var toastSuccess = this.$f7.toast.create({
          text: this.$t('upload success'),
          position: 'center',
          closeTimeout: 2000,
        })
        toastSuccess.open()
        // this.$f7router.back()
        this.$f7router.navigate('/manage/')
      })
      .catch(error => {
        if(error.response.status == 422){
          if (error.response.data.errors) {
          } else {
            if (error.response.data.message) {
              swal({
                icon: "warning",
                text: error.response.data.message,
              });
            }
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      });
    },
    handleExceed(files, fileList) {
        // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleRemove(file, fileList) {
      this.form.fileList = fileList
      axios.post('/api/engineer/resume_remove',{
        file:this.form.resume_ServerFileName
      }).then(response => {
        this.form.resume_ServerFileName = '';
      }).catch(error => {
        if (error.response.status == 422) {
          if (error.response.data.message){
            swal({
              icon: "warning",
              text: error.response.data.message,
            });
          }
        } else {
          swal({
            icon: "error",
            text: error,
          });
        }
      })
    },
    handleChange(file, fileList) {
      if (fileList[0].size > 500*1024) {
        fileList = []
        this.form.fileList = fileList
        var self = this;
        var toastSizeExceed = self.$f7.toast.create({
          text: '500kb以下のEXCELファイルのみUPLOAD可',
          position: 'center',
          closeTimeout: 2000,
        })
        toastSizeExceed.open();
        return
      } else {
        this.form.fileList = fileList
      }
      let formData = new FormData()
      formData.append('file', fileList[0].raw)
      axios.post('/api/engineer/resume_upload', formData)
      .then(response => {
        this.form.resume_ServerFileName = response.data.path
      })
      .catch(error => {
        swal({
          icon: "error",
          text: error,
        });
      });
    },
  },
};
</script>
<style>
</style>
