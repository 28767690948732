<template>
  <f7-page>
    <f7-navbar :back-link="$t('back')" :title="form.project_name"></f7-navbar>
    <f7-list no-hairlines-md form class="">
        <!-- 管理No -->
        <f7-list-input
            :label="$t('management No')"
            type="text"
            :value="form.id"
            readonly>
        </f7-list-input>
        <!-- 登録更新日時 -->
        <f7-list-input
            :label="$t('updated at')"
            type="text"
            :value="form.updated_at"
            readonly>
        </f7-list-input>
        <!-- 更新社氏名 -->
        <f7-list-input
            :label="$t('updater name')"
            type="text"
            :value="form.admins_name"
            readonly>
        </f7-list-input>
        <!-- 案件説明詳細 -->
        <f7-list-input
            outline
            :label="$t('req description')"
            type="textarea"
            resizable
            :value="form.req_desc"
            readonly>
        </f7-list-input>
        <!-- スキル -->
        <f7-list-input
            :label="$t('skill')"
            type="text"
            :value="form.skill"
            readonly>
        </f7-list-input>
        <!-- 語学能力 -->
        <f7-list-input
            :label="$t('language skills')"
            type="text"
            :value="form.foreign_language"
            readonly>
        </f7-list-input>
        <!-- 最寄りの駅 -->
        <f7-list-input
            :label="$t('nearest station')"
            type="text"
            :value="form.nearest_station"
            readonly>
        </f7-list-input>
        <!-- 稼働可能日 -->
        <f7-list-input
            :label="$t('available day')"
            type="text"
            :value="form.available_day"
            readonly>
        </f7-list-input>
        <!-- 参画工程 -->
        <f7-list-input
            :label="$t('process')"
            type="text"
            :value="form.participation_process"
            readonly>
        </f7-list-input>
        <!-- 管理経験 -->
        <f7-list-input
            :label="$t('manage experience')"
            type="text"
            :value="form.manage_experience"
            readonly>
        </f7-list-input>
        <!-- 経験年数 -->
        <f7-list-input
            :label="$t('experience years')"
            type="text"
            :value="form.experience_years"
            readonly>
        </f7-list-input>
        <!-- 希望単価 -->
        <f7-list-input
            :label="$t('desired price')"
            type="text"
            :value="form.desired_price"
            readonly>
        </f7-list-input>
        <!-- 所属会社 -->
        <!-- <f7-list-input
            :label="$t('company belongs')"
            type="text"
            :value="form.company_name"
            readonly>
        </f7-list-input> -->
        <!-- wechat -->
        <f7-list-input
            :label="$t('wechat no')"
            type="text"
            :value="form.wechat_no"
            readonly>
        </f7-list-input>
        <!-- コメント -->
        <f7-list-input
            :label="$t('comment')"
            type="text"
            :value="form.comment"
            readonly>
        </f7-list-input>
        <f7-list-button v-if="platformId=='ios'"
            @click="$f7router.back()">
            {{ $t('back') }}
        </f7-list-button>
        <f7-list-item v-else>
            <f7-col></f7-col>
            <f7-col>
              <f7-button large @click="$f7router.back()">
                {{ $t('back') }}
              </f7-button>
            </f7-col>
            <f7-col></f7-col>
        </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import moment from 'moment';

export default {
  name: 'DetailProject',
  data() {
    return {
      project_id: null,
      form: {},
      platformId: 'ios',
    };
  },
  created() {
    if (!Device.cordova) {
      this.platformId = 'ios'  //pcの場合iosとする
    } else {
      this.platformId = cordova.platformId //ios,android,osx
    }
  },
  mounted() {
    this.project_id = this.$f7route.params.id
    this.get_project()
  },
  methods: {
    get_project() {
      const options = {
        params: {
          id: this.project_id,
        }
      };
      axios.get('/api/admin/project_open/get_project', options)
      .then(response => {
        let item = response.data.project
        this.form = item
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
  }
}
</script>
<style>
</style>
