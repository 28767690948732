// import Form from 'vform'
import * as types from '../mutation-types'

// state
export const state = {
  QueryConditions: [],
  QueryCount: 0
}

// getters
export const getters = {
  QueryConditions: state => state.QueryConditions,
  QueryCount:      state => state.QueryCount,
}

// mutations
export const mutations = {
  [types.UPDATE_ENGINEER_CONDITIONS] (state, { QueryConditions }) {
    state.QueryConditions = QueryConditions,
    state.QueryCount = state.QueryCount + 1
  },

  [types.CLEAR_ENGINEER_CONDITIONS] (state) {
    state.QueryConditions = [],
    state.QueryCount = 0
  },
}

// actions
export const actions = {
  updateEngineerConditions ({ commit }, payload) {
    commit(types.UPDATE_ENGINEER_CONDITIONS, payload)
  },

  clearEngineerConditions ({ commit }, payload) {
    commit(types.CLEAR_ENGINEER_CONDITIONS, payload)
  }
}
