<template>
  <f7-page>
    <f7-navbar :title="$t('page not found')" :back-link="$t('back')"></f7-navbar>
    <f7-block strong>
      <!-- <p>Sorry</p> -->
      <p>{{ $t('page not found') }}</p>
    </f7-block>
  </f7-page>
</template>
<script>
  export default {};
</script>
