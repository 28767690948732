<template>
  <f7-segmented raised tag="p">
    <f7-button outline small v-on:click.stop.prevent="select_month('previous')">{{ $t('previous month') }}</f7-button>
    <f7-button outline small v-on:click.stop.prevent="select_month('previous')">{{page_month.previous_month.label}}</f7-button>
    <f7-button outline small active>{{page_month.current_month.label}}</f7-button>
    <f7-button outline small v-on:click.stop.prevent="select_month('next')">{{page_month.next_month.label}}</f7-button>
    <f7-button outline small v-on:click.stop.prevent="select_month('next')">{{ $t('next month') }}</f7-button>
  </f7-segmented>
  <!-- <ul class="pagination pagination-sm">
    <li class="page-item">
      <a class="page-link" href="#" v-on:click.stop.prevent="select_month('previous')">{{ $t('previous month') }}</a>
    </li>
    <li class="page-item">
      <a class="page-link" href="#" v-on:click.stop.prevent="select_month('previous')">
        {{page_month.previous_month.label}}</a></li>
    <li class="page-item active" aria-current="page">
      <span class="page-link">
        {{page_month.current_month.label}}
        <span class="sr-only">(current)</span>
      </span>
    </li>
    <li class="page-item">
      <a class="page-link" href="#" v-on:click.stop.prevent="select_month('next')">
        {{page_month.next_month.label}}</a></li>
    <li class="page-item">
      <a class="page-link" href="#" v-on:click.stop.prevent="select_month('next')">{{ $t('next month') }}</a>
    </li>
  </ul> -->
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'MonthManage',
  data: () => ({
    page_month: {
      current_month: {
        label: '',
        value: '',
      },
      previous_month: {
        label: '',
        value: '',
      },
      next_month: {
        label: '',
        value: '',
      },
    },
  }),

  mounted () {
    this.get_month_manage()
    this.$store.dispatch('monthManage/updateMonth', {
      current_month: this.page_month.current_month.value
    })
  },

  methods: {
    select_month(sel_month) {
      if (sel_month == 'previous') {
        this.page_month.current_month.value = this.page_month.previous_month.value
      } else if (sel_month == 'next') {
        this.page_month.current_month.value = this.page_month.next_month.value
      }
      // current_monthを保存
      this.$store.dispatch('monthManage/updateMonth', {
        current_month: this.page_month.current_month.value
      })
      this.get_month_manage()
    },
    async get_month_manage() {
      const options = {
        params: {
          current_month: this.page_month.current_month.value,
        }
      };
      const { data } = await axios.get('/api/admin/project/get_month_manage', options)
      this.page_month = data.page_month
    },
  }
}
</script>
<style>
</style>
