<template>
  <div class="table_panel">
    <slot/>
  </div>
</template>

<script>
  export default {
    name: 'TablePanel',
  }
</script>
<style>
  .table_panel {
      float: left;
      position: relative;
      width: 100%;
      margin-top: 5px;
      margin-left: 1px;
      margin-right: 1px;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: 0px solid #ccc;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  }
</style>
