import Vue from 'vue'
import store from '~/js/store'
import VueI18n from 'vue-i18n'

import enLocale from 'element-ui/lib/locale/lang/en'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import esLocale from 'element-ui/lib/locale/lang/es'
import ElementLocale from 'element-ui/lib/locale'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {}
})

/**
 * @param {String} locale
 */
export async function loadMessages (locale) {
  if (Object.keys(i18n.getLocaleMessage(locale)).length === 0) {
    const messages = await import(/* webpackChunkName: "lang-[request]" */ `~/lang/${locale}.json`)
    i18n.setLocaleMessage(locale, messages)
  }
  if (i18n.locale !== locale) {
    i18n.locale = locale
  }

  //设置语言
  if (locale == 'en') {
    ElementLocale.use(enLocale)
  } else if (locale == 'ja') {
    ElementLocale.use(jaLocale)
  } else if (locale == 'zh-CN') {
    ElementLocale.use(zhLocale)
  } else if (locale == 'es') {
    ElementLocale.use(esLocale)
  }
}

;(async function () {
  await loadMessages(store.getters['lang/locale'])
})()

export default i18n
