import * as types from '../mutation-types'

// state
export const state = {
  isCollapse: false,
  menu_width: '200px'
}

// getters
export const getters = {
  isCollapse: state => state.isCollapse,
  menu_width: state => state.menu_width,
}

// mutations
export const mutations = {
  [types.UPDATE_COLLAPSE] (state, { isCollapse, menu_width }) {
    state.isCollapse = isCollapse,
    state.menu_width = menu_width
  }
}

// actions
export const actions = {
  updateCollapse ({ commit }, payload) {
    commit(types.UPDATE_COLLAPSE, payload)
  }
}
