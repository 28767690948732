<template>
  <f7-button outline small :text="month_current.label" popover-open=".popover-month-switch-manage">
    <f7-popover ref="popoverMonthSwitchManage" class="popover-month-switch-manage">
      <f7-list>
        <f7-list-item
            v-for="(item,index) in month_switch"
            :key="item.value"
            v-on:click.stop.prevent="month_select(item)"
            :title="item.label"
            :after="item.value==month_current.value? '√' : ''"
        ></f7-list-item>
      </f7-list>
    </f7-popover>
  </f7-button>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'MonthSwitch_manage',
  data: () => ({
    month_current: {
      value: '',
      label: '',
    },
    month_switch: [],
    month_default: {
      value: '',
      label: '',
    },
    select_month: '',
  }),

  mounted () {
    this.get_month_switch()
  },
  computed: mapGetters({
    current_month_stored: 'monthSwitch/current_month_manage',
  }),
  watch: {
    current_month_stored: function (newValue, oldValue) {
      this.month_current = newValue
    },
  },
  methods: {
    month_select(item) {
      this.$refs.popoverMonthSwitchManage.close()
      // current_monthを保存
      this.$store.dispatch('monthSwitch/updateMonthManage', {
        current_month_manage: item
      })
      this.month_current = item
    },
    async get_month_switch() {
      const { data } = await axios.get('/api/admin/project/get_month_switch')
      this.month_switch = data.month_switch
      const current_month = this.$store.state.monthSwitch.current_month_manage
      if (current_month) {
        this.month_current = current_month
      } else {
        this.month_current = this.month_switch[3]
        this.$store.dispatch('monthSwitch/updateMonthManage', {
          current_month_manage: this.month_current
        })
      }
    },
  }
}
</script>
