import * as types from '../mutation-types'

// state
export const state = {
  added_notice_id: '',
  added_project_id: '',
  added_engineer_id: '',
  added_admin_id: '',
  added_company_id: '',
  updated_company: null,
  app_version_newest: '',
  added_complaint_id: '',
}

// getters
export const getters = {
  added_notice_id: state => state.added_notice_id,
  added_project_id: state => state.added_project_id,
  added_engineer_id: state => state.added_engineer_id,
  added_admin_id: state => state.added_admin_id,
  added_company_id: state => state.added_company_id,
  updated_company: state => state.updated_company,
  app_version_newest: state => state.app_version_newest,
  added_complaint_id: state => state.added_complaint_id
}

// mutations
export const mutations = {
  [types.ADD_NOTICE] (state, { added_notice_id }) {
    state.added_notice_id = added_notice_id
  },
  [types.ADD_PROJECT] (state, { added_project_id }) {
    state.added_project_id = added_project_id
  },
  [types.ADD_ENGINEER] (state, { added_engineer_id }) {
    state.added_engineer_id = added_engineer_id
  },
  [types.ADD_COMPANY] (state, { added_admin_id, added_company_id }) {
    state.added_admin_id = added_admin_id,
    state.added_company_id = added_company_id
  },
  [types.UPDATE_COMPANY] (state, { updated_company }) {
    state.updated_company = updated_company
  },
  [types.VERSION_UP] (state, { app_version_newest }) {
    state.app_version_newest = app_version_newest
  },
  [types.ADD_COMPLAINT] (state, { added_complaint_id }) {
    state.added_complaint_id = added_complaint_id
  },
}

// actions
export const actions = {
  addNotice ({ commit }, payload) {
    commit(types.ADD_NOTICE, payload)
  },
  addProject ({ commit }, payload) {
    commit(types.ADD_PROJECT, payload)
  },
  addEngineer ({ commit }, payload) {
    commit(types.ADD_ENGINEER, payload)
  },
  addCompany ({ commit }, payload) {
    commit(types.ADD_COMPANY, payload)
  },
  updateCompany ({ commit }, payload) {
    commit(types.UPDATE_COMPANY, payload)
  },
  versionUp ({ commit }, payload) {
    commit(types.VERSION_UP, payload)
  },
  addComplaint ({ commit }, payload) {
    commit(types.ADD_COMPLAINT, payload)
  },
}
