<template>
  <f7-button outline small :text="sort_current.label" popover-open=".popover-sort-switch-project">
    <f7-popover ref="popoverSortSwitchProject" class="popover-sort-switch-project">
      <f7-list>
        <f7-list-item
            v-for="(item,index) in sort_switch"
            :key="item.value"
            v-on:click.stop.prevent="sort_select(item)"
            :title="item.label"
            :after="item.value==sort_current.value? '√' : ''"
        ></f7-list-item>
      </f7-list>
    </f7-popover>
  </f7-button>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SortSwitchProjectOpen',
  data: () => ({
    sort_current: {
      value: '',
      label: '',
    },
    sort_switch: [
      {
        value: '1',
        label: '登録日 ↑',
      },{
        value: '2',
        label: '登録日 ↓',
      },{
        value: '3',
        label: '単価 ↑',
      },{
        value: '4',
        label: '単価 ↓',
      },{
        value: '5',
        label: '稼働日 ↑',
      },{
        value: '6',
        label: '稼働日 ↓',
      },
    ],
    sort_default: {
      value: '1',
      label: '登録日 ↑',
    },
  }),

  mounted () {
    const project_open_sort = this.$store.state.sortSwitch.project_open_sort
    if (project_open_sort) {
      this.sort_current = project_open_sort
    } else {
      this.sort_current = this.sort_default
      this.$store.dispatch('sortSwitch/updateProjectOpenSort', {
        project_open_sort: this.sort_current
      })
    }
  },
  methods: {
    sort_select(item) {
      this.$refs.popoverSortSwitchProject.close()
      // current_monthを保存
      this.$store.dispatch('sortSwitch/updateProjectOpenSort', {
        project_open_sort: item
      })
      this.sort_current = item
    },
  }
}
</script>
<style>
</style>
