<template>
  <f7-page class="grid-demo" ptr :ptr-mousewheel="true" @ptr:refresh="loadMore"
      infinite
      :infinite-distance="50"
      :infinite-preloader="showPreloader"
      @infinite="loadMore">
    <f7-navbar>
      <!-- <f7-nav-left>
        <f7-link v-on:click.stop.prevent="AddEngineer()">
          <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        </f7-link>
      </f7-nav-left> -->
      <f7-nav-left>
        <f7-link popover-open=".popover-menu">
          <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t('manage') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-manage" icon-ios="f7:search" icon-md="material:search" icon-aurora="f7:search"></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-manage"
        expandable
        search-container=".search-list"
        search-in=".item-header, .item-title, .item-subtitle, .item-text, .item-footer"
        :disable-button="!$theme.aurora"
        :disable-button-text="$t('cancel')"
        :placeholder="$t('search')"
        :clear-button="true"
      ></f7-searchbar>
      <f7-subnavbar>
        <f7-segmented raised>
          <MonthSwitch/>
          <SortSwitch/>
        </f7-segmented>
      </f7-subnavbar>
    </f7-navbar>
    <f7-list class="searchbar-not-found">
      <f7-list-item :title="$t('Nothing found')"></f7-list-item>
    </f7-list>

    <!-- top left menu  -->
    <f7-popover ref="popoverMenu" class="popover-menu">
      <f7-list>
        <f7-list-item v-on:click.stop.prevent="AddProject()" :title="$t('project add')">
        </f7-list-item>
        <f7-list-item v-on:click.stop.prevent="AddEngineer()" :title="$t('engineer add')">
        </f7-list-item>
      </f7-list>
    </f7-popover>

    <f7-block-title>{{ $t('project manage') }}</f7-block-title>
    <f7-list accordion-list>
      <f7-list-item accordion-item :title="$t('open')" :badge="project_pagination_online.total" badge-color="green">
        <f7-accordion-content>
          <f7-list media-list class="search-list searchbar-found">
            <f7-list-item v-for="(item,index) in project_items_online"
              swipeout
              :link="'/detail_project/'+item.id+'/'"
              :header="item.updated_at"
              :title="item.project_name"
              :subtitle="item.skill"
              :text="item.req_desc"
              :footer="'管理No:'+item.id"
              :after="item.available_day"
              :key="index">
              <f7-swipeout-actions right>
                <f7-swipeout-button @click="more(index,item); $refs.actionsOneGroup_p_online.open()">...</f7-swipeout-button>
                <f7-swipeout-button color="orange" @click="UpdateProject(index,item)">{{ $t('edit') }}</f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item accordion-item :title="$t('unopen')" :badge="project_pagination_offline.total" badge-color="red">
        <f7-accordion-content>
          <f7-list media-list class="search-list searchbar-found">
            <f7-list-item v-for="(item,index) in project_items_offline"
              swipeout
              :link="'/detail_project/'+item.id+'/'"
              :header="item.updated_at"
              :title="item.project_name"
              :subtitle="item.skill"
              :text="item.req_desc"
              :footer="'管理No:'+item.id"
              :after="item.available_day"
              :key="index">
              <f7-swipeout-actions right>
                <f7-swipeout-button @click="more(index,item); $refs.actionsOneGroup_p_offline.open()">...</f7-swipeout-button>
                <f7-swipeout-button color="orange" @click="UpdateProject(index,item)">{{ $t('edit') }}</f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <f7-block-title>{{ $t('engineer manage') }}</f7-block-title>
    <f7-list accordion-list>
      <f7-list-item accordion-item :title="$t('open')" :badge="engineer_pagination_online.total" badge-color="green">
        <f7-accordion-content>
          <f7-list media-list class="search-list searchbar-found">
            <f7-list-item v-for="(item,index) in engineer_items_online"
              swipeout
              :link="'/detail_engineer/'+item.id+'/'"
              :header="item.name_sex_age+', 登録日:'+item.updated_at"
              :title="item.foreign_language+','+item.experience_years_str"
              :subtitle="item.skill"
              :text="item.business_experience+','+item.participation_process+','+item.manage_experience"
              :footer="'管理No:'+item.id"
              :after="item.available_day+', '+item.desired_price"
              :key="index">
              <f7-swipeout-actions right>
                <f7-swipeout-button @click="more(index,item); $refs.actionsOneGroup_e_online.open()">...</f7-swipeout-button>
                <f7-swipeout-button color="orange" @click="UpdateEngineer(index,item)">{{ $t('edit') }}</f7-swipeout-button>
                <f7-swipeout-button
                    v-if="item.resume_ServerFileName"
                    color="green"
                    v-on:click.stop.prevent="handleSkillDownload(index,item)">{{ $t('resume download') }}
                </f7-swipeout-button>
                <f7-swipeout-button
                    v-else
                    color="blue"
                    v-on:click.stop.prevent="handleSkillUpload(index,item)">{{ $t('resume upload') }}
                </f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item accordion-item :title="$t('unopen')" :badge="engineer_pagination_offline.total" badge-color="red">
        <f7-accordion-content>
          <f7-list media-list class="search-list searchbar-found">
            <f7-list-item v-for="(item,index) in engineer_items_offline"
              swipeout
              :link="'/detail_engineer/'+item.id+'/'"
              :header="item.name_sex_age+', 登録日:'+item.updated_at"
              :title="item.foreign_language+','+item.experience_years_str"
              :subtitle="item.skill"
              :text="item.business_experience+','+item.participation_process+','+item.manage_experience"
              :footer="'管理No:'+item.id"
              :after="item.available_day+', '+item.desired_price"
              :key="index">
              <f7-swipeout-actions right>
                <f7-swipeout-button v-on:click.stop.prevent="more(index,item); $refs.actionsOneGroup_e_offline.open()">...</f7-swipeout-button>
                <f7-swipeout-button color="orange" v-on:click.stop.prevent="UpdateEngineer(index,item)">{{ $t('edit') }}</f7-swipeout-button>
                <f7-swipeout-button
                    v-if="item.resume_ServerFileName"
                    color="green"
                    v-on:click.stop.prevent="handleSkillDownload(index,item)">{{ $t('resume download') }}
                </f7-swipeout-button>
                <f7-swipeout-button
                    v-else
                    color="blue"
                    v-on:click.stop.prevent="handleSkillUpload(index,item)">{{ $t('resume upload') }}
                </f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <f7-actions ref="actionsOneGroup_p_online" id="actionsOneGroup_p_online">
      <f7-actions-group>
        <!-- <f7-actions-label>Do something</f7-actions-label> -->
        <f7-actions-button v-on:click.stop.prevent="handleOffline('project')">
            {{ $t('unopen') }}
        </f7-actions-button>
        <f7-actions-button color="red" v-on:click.stop.prevent="handleDelete('project','on')">
            {{ $t('delete') }}
        </f7-actions-button>
        <f7-actions-button>
            {{ $t('cancel') }}
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>
    <f7-actions ref="actionsOneGroup_p_offline" id="actionsOneGroup_p_offline">
      <f7-actions-group>
        <f7-actions-button v-on:click.stop.prevent="handleOnline('project')">
            {{ $t('open') }}
        </f7-actions-button>
        <f7-actions-button color="red" v-on:click.stop.prevent="handleDelete('project','off')">
            {{ $t('delete') }}
        </f7-actions-button>
        <f7-actions-button>
            {{ $t('cancel') }}
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>
    <f7-actions ref="actionsOneGroup_e_online" id="actionsOneGroup_e_online">
      <f7-actions-group>
        <!-- <f7-actions-label>Do something</f7-actions-label> -->
        <f7-actions-button v-on:click.stop.prevent="handleOffline('engineer')">
            {{ $t('unopen') }}
        </f7-actions-button>
        <f7-actions-button color="red" v-on:click.stop.prevent="handleDelete('engineer','on')">
            {{ $t('delete') }}
        </f7-actions-button>
        <f7-actions-button>
            {{ $t('cancel') }}
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>
    <f7-actions ref="actionsOneGroup_e_offline" id="actionsOneGroup_e_offline">
      <f7-actions-group>
        <f7-actions-button v-on:click.stop.prevent="handleOnline('engineer')">
            {{ $t('open') }}
        </f7-actions-button>
        <f7-actions-button color="red" v-on:click.stop.prevent="handleDelete('engineer','off')">
            {{ $t('delete') }}
        </f7-actions-button>
        <f7-actions-button>
            {{ $t('cancel') }}
        </f7-actions-button>
      </f7-actions-group>
    </f7-actions>

    <f7-list accordion-list>
      <f7-list-item accordion-item :title="$t('favorites')" :badge="project_items_favorites.length" badge-color="green">
        <f7-accordion-content>
          <f7-list media-list class="search-list searchbar-found">
            <f7-list-item v-for="(item,index) in project_items_favorites"
              swipeout
              :link="'/detail_project_open/'+item.id+'/'"
              :header="item.updated_at"
              :title="item.project_name"
              :subtitle="item.skill"
              :text="item.req_desc"
              :footer="item.company_name+',管理No:'+item.id"
              :after="item.available_day"
              :key="index">
              <f7-swipeout-actions right>
                <f7-swipeout-button
                  color="red"
                  v-on:click.stop.prevent="handleDeleteFavorite(index,item)">{{ $t('delete') }}
                </f7-swipeout-button>
              </f7-swipeout-actions>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

  </f7-page>
</template>

<script>
import { Device }  from 'framework7/framework7-lite.esm.bundle.js';
import axios from 'axios'
import swal from "sweetalert"
import { mapGetters } from 'vuex'
import * as FileSaver from 'file-saver'
import MonthSwitch from '~/js/components/MonthSwitch_manage'
import SortSwitch from '~/js/components/SortSwitch_manage'

export default {
  components: {
    MonthSwitch,
    SortSwitch,
  },
  metaInfo() {
    return { title: this.$t('manage') }
  },
  data() {
    return {
      allowInfinite: true,
      showPreloader: true,
      curr_item: {
        item: null,
        index: null
      },
      engineer_items_online: [],
      engineer_items_online_getall: false,
      engineer_items_offline: [],
      engineer_items_offline_getall: false,
      project_items_online: [],
      project_items_online_getall: false,
      project_items_offline: [],
      project_items_offline_getall: false,
      project_items_favorites: [],
      project_items_favorites_getall: false,
      engineer_pagination_online: {
        per_page: 50,     // 每页件数 固定
        pager_count: 3,  // 显示页数 固定
        current_page: 1, // 当前页 変わる
        total: 0,        // 总条目数
      },
      engineer_pagination_offline: {
        per_page: 50,     // 每页件数 固定
        pager_count: 3,  // 显示页数 固定
        current_page: 1, // 当前页 変わる
        total: 0,        // 总条目数
      },
      project_pagination_online: {
        per_page: 50,     // 每页件数 固定
        pager_count: 3,  // 显示页数 固定
        current_page: 1, // 当前页 変わる
        total: 0,        // 总条目数
      },
      project_pagination_offline: {
        per_page: 50,     // 每页件数 固定
        pager_count: 3,  // 显示页数 固定
        current_page: 1, // 当前页 変わる
        total: 0,        // 总条目数
      },
      project_pagination_favorites: {
        per_page: 50,     // 每页件数 固定
        pager_count: 3,  // 显示页数 固定
        current_page: 1, // 当前页 変わる
        total: 0,        // 总条目数
      },
      current_month: {
        value: '0',
        label: '',
      },
      sort_current: {
        value: '1',
        label: '登録時間 ↑',
      },
      user: null,
      user_role_id: null,
      company: null,
    }
  },
  created() {
    this.user = this.$store.state.auth.user
    this.company = this.user.company
    this.user_role_id = this.$store.state.role_id
  },
  mounted() {
    const manage_sort = this.$store.state.sortSwitch.manage_sort;
    if (manage_sort) {
      this.sort_current = manage_sort
    }
    const current_month_stored = this.$store.state.monthSwitch.current_month_manage;
    if (current_month_stored) {
      this.current_month = current_month_stored
    }
    this.init('all')
    this.loadMore()
  },
  computed: mapGetters({
    current_month_stored: 'monthSwitch/current_month_manage',
    manage_sort: 'sortSwitch/manage_sort',
    updated_company: 'pushed/updated_company',
    added_favorites_id: 'transfered/added_favorites_id',
  }),
  watch: {
    // 如果 `current_month` 发生改变，这个函数就会运行
    current_month_stored: function (newCurrentMonth, oldCurrentMonth) {
      this.current_month = newCurrentMonth
      this.init('all')
      this.loadMore()
    },
    manage_sort: function (newVal, oldVal) {
      this.sort_current = newVal
      this.init('all')
      this.loadMore()
    },
    updated_company: function (newVal, oldVal) {
      this.company = newVal
    },
    added_favorites_id: function (newVal, oldVal) {
      if (newVal) {
        this.getProjectList_favorites(newVal)
      }
    },
    engineer_items_online_getall: function (newVal, oldVal) {
      if (this.project_items_online_getall==true && this.project_items_offline_getall==true &&
          newVal==true && this.engineer_items_offline_getall==true) {
        this.showPreloader = false;
        this.allowInfinite = false;
      }
    },
    engineer_items_offline_getall: function (newVal, oldVal) {
      if (this.project_items_online_getall==true && this.project_items_offline_getall==true &&
          this.engineer_items_online_getall==true && newVal==true) {
        this.showPreloader = false;
        this.allowInfinite = false;
      }
    },
    project_items_online_getall: function (newVal, oldVal) {
      if (newVal==true && this.project_items_offline_getall==true &&
          this.engineer_items_online_getall==true && this.engineer_items_offline_getall==true) {
        this.showPreloader = false;
        this.allowInfinite = false;
      }
    },
    project_items_offline_getall: function (newVal, oldVal) {
      if (this.project_items_online_getall==true && newVal==true &&
          this.engineer_items_online_getall==true && this.engineer_items_offline_getall==true) {
        this.showPreloader = false;
        this.allowInfinite = false;
      }
    },
  },
  methods: {
    onEngineerChange: function (e) {
      var value = e.target.value;
      if (e.target.checked) {
        this.engineer_list_checkbox.push(value);
      } else {
        this.engineer_list_checkbox.splice(this.engineer_list_checkbox.indexOf(value), 1);
      }
    },
    init(projectengineer) {
      if (projectengineer == 'all') {
        this.engineer_pagination_online.current_page = 0
        this.engineer_pagination_online.total = 0
        this.engineer_items_online = []
        this.engineer_items_online_getall = false
        this.engineer_pagination_offline.current_page = 0
        this.engineer_pagination_offline.total = 0
        this.engineer_items_offline = []
        this.engineer_items_offline_getall = false
        this.project_pagination_online.current_page = 0
        this.project_pagination_online.total = 0
        this.project_items_online = []
        this.project_items_online_getall = false
        this.project_pagination_offline.current_page = 0
        this.project_pagination_offline.total = 0
        this.project_items_offline = []
        this.project_items_offline_getall = false
        this.project_pagination_favorites.current_page = 0
        this.project_pagination_favorites.total = 0
        this.project_items_favorites = []
        this.project_items_favorites_getall = false
      } else if (projectengineer == 'engineer') {
        this.engineer_pagination_online.current_page = 0
        this.engineer_pagination_online.total = 0
        this.engineer_items_online = []
        this.engineer_items_online_getall = false
        this.engineer_pagination_offline.current_page = 0
        this.engineer_pagination_offline.total = 0
        this.engineer_items_offline = []
        this.engineer_items_offline_getall = false
      } else {
        this.project_pagination_online.current_page = 0
        this.project_pagination_online.total = 0
        this.project_items_online = []
        this.project_items_online_getall = false
        this.project_pagination_offline.current_page = 0
        this.project_pagination_offline.total = 0
        this.project_items_offline = []
        this.project_items_offline_getall = false
      }
      this.curr_item.item = null
      this.curr_item.index = null
      this.allowInfinite = true
      this.showPreloader = true
    },
    loadMore(done) {
      if (done) {
        // console.log("!!!pull refresh!!!")
        this.init('all')
        this.showPreloader = false
      } else {
        this.showPreloader = false
      }
      // const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      setTimeout(() => {
        if (this.engineer_items_online_getall==false) {
          if (this.engineer_items_online.length >= 2000) {
            this.engineer_items_online_getall = true
          } else {
            this.engineer_pagination_online.current_page = this.engineer_pagination_online.current_page + 1
            this.getEngineerList_online()
          }
        }
        if (this.engineer_items_offline_getall==false) {
          if (this.engineer_items_offline.length >= 2000) {
            this.engineer_items_offline_getall = true
          } else {
            this.engineer_pagination_offline.current_page = this.engineer_pagination_offline.current_page + 1
            this.getEngineerList_offline()
          }
        }
        if (this.project_items_online_getall==false) {
          if (this.project_items_online.length >= 2000) {
            this.project_items_online_getall = true
          } else {
            this.project_pagination_online.current_page = this.project_pagination_online.current_page + 1
            this.getProjectList_online()
          }
        }
        if (this.project_items_offline_getall==false) {
          if (this.project_items_offline.length >= 2000) {
            this.project_items_offline_getall = true
          } else {
            this.project_pagination_offline.current_page = this.project_pagination_offline.current_page + 1
            this.getProjectList_offline()
          }
        }
        if (this.project_items_favorites_getall==false) {
          if (this.project_items_favorites.length >= 2000) {
            this.project_items_favorites_getall = true
          } else {
            this.project_pagination_favorites.current_page = this.project_pagination_favorites.current_page + 1
            this.getProjectList_favorites(null)
          }
        }
        this.allowInfinite = true;
        if (done) {
          done()
        }
      }, 1000);
    },
    async getProjectList_favorites(added_favorites_id) {
      const options = {
        params: {
          paginate: this.project_pagination_favorites.per_page,
          page: this.project_pagination_favorites.current_page,
          current_month: this.current_month.value,
          current_sort: this.sort_current.value,
          added_favorites_id: added_favorites_id
        }
      };
      await axios.get('/api/admin/project_open/get_project_favorites_list', options)
      .then(response => {
        if (!added_favorites_id) {
          let data = response.data.list.data
          this.project_pagination_favorites.total = response.data.list.total
          this.project_items_favorites.current_page = response.data.list.current_page
          this.project_items_favorites.push(...data)
          if (this.project_items_favorites.length === this.project_pagination_favorites.total) {
            this.project_items_favorites_getall = true
          }
          if (this.project_pagination_favorites.total == 0) {
            this.project_items_favorites_getall = true
          }
        } else {
          let data = response.data.list
          this.project_items_favorites.unshift(...data)
        }
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    async getProjectList_online() {
      const options = {
        params: {
          paginate: this.project_pagination_online.per_page,
          page: this.project_pagination_online.current_page,
          current_month: this.current_month.value,
          current_sort: this.sort_current.value,
          on_off_line: 'online'
        }
      };
      await axios.get('/api/admin/project/get_project_list_own', options)
      .then(response => {
        let data = response.data.list.data
        this.project_pagination_online.total = response.data.list.total
        this.project_pagination_online.current_page = response.data.list.current_page
        this.project_items_online.push(...data)
        // console.log(this.project_pagination_online.current_page)
        // console.log(this.project_pagination_online.total)
        // console.log(this.project_items_online.length)
        if (this.project_items_online.length === this.project_pagination_online.total) {
          this.project_items_online_getall = true
        }
        if (this.project_pagination_online.total == 0) {
          this.project_items_online_getall = true
        }
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    async getProjectList_offline() {
      const options = {
        params: {
          paginate: this.project_pagination_offline.per_page,
          page: this.project_pagination_offline.current_page,
          current_month: this.current_month.value,
          current_sort: this.sort_current.value,
          on_off_line: 'offline'
        }
      };
      await axios.get('/api/admin/project/get_project_list_own', options)
      .then(response => {
        let data = response.data.list.data
        this.project_pagination_offline.total = response.data.list.total
        this.project_pagination_offline.current_page = response.data.list.current_page
        this.project_items_offline.push(...data)
        if (this.project_items_offline.length === this.project_pagination_offline.total) {
          this.project_items_offline_getall = true
        }
        if (this.project_pagination_offline.total == 0) {
          this.project_items_offline_getall = true
        }
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    async getEngineerList_online() {
      const options = {
        params: {
          paginate: this.engineer_pagination_online.per_page,
          page: this.engineer_pagination_online.current_page,
          current_month: this.current_month.value,
          current_sort: this.sort_current.value,
          on_off_line: 'online'
        }
      };
      await axios.get('/api/admin/engineer/get_engineer_list_own', options)
      .then(response => {
        let data = response.data.list.data
        if (data) {
          this.engineer_pagination_online.total = response.data.list.total
          this.engineer_pagination_online.current_page = response.data.list.current_page
          this.engineer_items_online.push(...data)
        }
        if (this.engineer_items_online.length === this.engineer_pagination_online.total) {
          this.engineer_items_online_getall = true
        }
        if (this.engineer_pagination_online.total == 0) {
          this.engineer_items_online_getall = true
        }
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    async getEngineerList_offline() {
      const options = {
        params: {
          paginate: this.engineer_pagination_offline.per_page,
          page: this.engineer_pagination_offline.current_page,
          current_month: this.current_month.value,
          current_sort: this.sort_current.value,
          on_off_line: 'offline'
        }
      };
      await axios.get('/api/admin/engineer/get_engineer_list_own', options)
      .then(response => {
        let data = response.data.list.data
        this.engineer_pagination_offline.total = response.data.list.total
        this.engineer_pagination_offline.current_page = response.data.list.current_page
        this.engineer_items_offline.push(...data)
        if (this.engineer_items_offline.length === this.engineer_pagination_offline.total) {
          this.engineer_items_offline_getall = true
        }
        if (this.engineer_pagination_offline.total == 0) {
          this.engineer_items_offline_getall = true
        }
      })
      .catch(function (error) {
        swal({
          icon: "error",
          text: error,
        })
      })
    },
    more(index, item) {
      // const self = this;
      // self.actions.open();
      this.curr_item.item = item
      this.curr_item.index = index
    },
    writeFile(fileEntry, dataObj, mimeType, cordova_platform) {
      // Create a FileWriter object for our FileEntry.
      fileEntry.createWriter(function (fileWriter) {
          fileWriter.onwriteend = function() {
              // console.log("Successful file write...");
              // if (cordova_platform == 'osx') {
              //   var url = fileEntry.toURL();
              //   cordova.plugins.fileOpener2.open(url, mimeType, {
              //     error: function error(err) {
              //       console.error(err);
              //       // alert("Unable to download");
              //     },
              //     success: function success() {
              //       console.log("success with opening the file");
              //     }
              //   });
              // }
          };
          fileWriter.onerror = function (e) {
              console.log("Failed file write: " + e.toString());
          };
          if (!dataObj) {
              // console.log("dataObj is null");
              dataObj = new Blob(['some file data'], { type: 'text/plain' });
          }
          fileWriter.write(dataObj);
      });
    },
    handleSkillDownload(index,item) {
      axios.get('/api/admin/engineer/skill_download?id='+item.id,{
          responseType:'blob',
          dataType:'binary',
      })
      .then(response => {
        var mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-16le"
        let blob = new Blob([response.data], {
          type: mimeType
        })
        if (!Device.cordova) {
          FileSaver.saveAs(blob, item.resume_file_name);
        } else {
          //https://esstudio.site/2019/02/16/downloading-saving-and-opening-files-with-cordova.html
          if (cordova.platformId == 'android') {
            window.resolveLocalFileSystemURL(
              cordova.file.externalApplicationStorageDirectory,
              (dir) => {
                dir.getFile(this.form.resume_file_name, {create: true},
                  (fileEntry) => {
                    // console.log('File creation successfull!');
                    // console.log("fileEntry is file?" + fileEntry.isFile.toString());
                    this.writeFile(fileEntry, blob, mimeType, 'android');
                  },
                  function(error) {
                    console.log("ERROR: " + error.code);
                  }
                );
              }
            );
          } else if (cordova.platformId == 'osx') {
            window.resolveLocalFileSystemURL(
              cordova.file.documentsDirectory,
              (dir) => {
                dir.getFile(this.form.resume_file_name, {create: true},
                  (fileEntry) => {
                    // console.log('File creation successfull!');
                    // console.log("fileEntry is file?" + fileEntry.isFile.toString());
                    this.writeFile(fileEntry, blob, mimeType, 'osx');
                  },
                  function(error) {
                    console.log("ERROR: " + error.code);
                  }
                );
              }
            );
          } else {
            window.requestFileSystem(
              LocalFileSystem.PERSISTENT,
              0,
              (fileSystem) => {
                // console.log('file system open: ' + fileSystem.name);
                fileSystem.root.getFile(item.resume_file_name, {create: true, exclusive: false},
                  (fileEntry) => {
                    // console.log('File creation successfull!');
                    // console.log("fileEntry is file?" + fileEntry.isFile.toString());
                    this.writeFile(fileEntry, blob, mimeType, 'osx');
                  },
                  function(error) {
                    console.log("ERROR: " + error.code);
                  }
                );
              }
            );
          }
          // https://www.bignerdranch.com/blog/working-with-the-files-app-in-ios-11/
          // https://github.com/apache/cordova-plugin-file
          // https://qiita.com/kondoakio/items/f4b01a3653a60fe7dcdb
          // https://docs.monaca.io/ja/reference/cordova_6.5/file/#ディレクトリーの作成
          // https://www.tutorialspoint.com/cordova/cordova_file_system.htm
          // http://cordova.axuer.com/docs/zh-cn/6.x/reference/cordova-plugin-file/index.html
        }
        // const app = this.$f7;
        // app.dialog.alert(item.resume_file_name+' download成功');
        var self = this;
        var toastSuccess = self.$f7.toast.create({
          text: item.resume_file_name+' download成功',
          position: 'center',
          closeTimeout: 2000,
        });
        toastSuccess.open();
      })
    },
    handleSkillUpload(index,item) {
      let options = {engineer_id: item.id}
      this.$f7router.navigate('/upload_engineer_resume/' + JSON.stringify(options))
    },
    handleOffline(projectengineer) {
      let url = ''
      if (projectengineer == 'engineer') {
        url = '/api/admin/engineer/unopen_engineer?id=' + this.curr_item.item.id
      } else {
        url = '/api/admin/project/unopen_project?id=' + this.curr_item.item.id
      }
      swal({
        title: this.$t('confirm unopen'),
        dangerMode: true,
        buttons: [this.$t('cancel'), this.$t('unopen')],
      }).then((value) => {
        if (value) {
          axios.get(url)
          .then(response => {
            var toastSuccess = this.$f7.toast.create({
              text: this.$t('unopen success'),
              position: 'center',
              closeTimeout: 2000,
            })
            toastSuccess.open()
            this.init(projectengineer)
            this.loadMore()
          })
          .catch(error => {
            if (error.response.status == 422) {
              if (error.response.data.message){
                swal({
                  icon: "warning",
                  text: error.response.data.message,
                });
              }
            } else {
              swal({
                icon: "error",
                text: error,
              });
            }
          });
        }
      });
    },
    handleOnline(projectengineer) {
      if (this.company.rule_violation_flag == 1) {
        const app = this.$f7
        app.dialog.alert('違約暫停中のため、公開できません')
        return
      }
      let url = ''
      if (projectengineer == 'engineer') {
        url = '/api/admin/engineer/open_engineer?id=' + this.curr_item.item.id
      } else {
        url = '/api/admin/project/open_project?id=' + this.curr_item.item.id
      }
      swal({
        title: this.$t('confirm open'),
        dangerMode: true,
        buttons: [this.$t('cancel'), this.$t('open')],
      }).then((value) => {
        if (value) {
          axios.get(url)
          .then(response => {
            var toastSuccess = this.$f7.toast.create({
              text: this.$t('open success'),
              position: 'center',
              closeTimeout: 2000,
            })
            toastSuccess.open()
            this.init(projectengineer)
            this.loadMore()
          })
          .catch(error => {
            if (error.response.status == 422) {
              if (error.response.data.message){
                swal({
                  icon: "warning",
                  text: error.response.data.message,
                });
              }
            } else {
              swal({
                icon: "error",
                text: error,
              });
            }
          });
        }
      });
    },
    handleDelete(projectengineer,onoff) {
      let url = ''
      if (projectengineer == 'engineer') {
        url = '/api/admin/engineer/delete_engineer?id=' + this.curr_item.item.id
      } else {
        url = '/api/admin/project/delete_project_mobile?id=' + this.curr_item.item.id
      }
      swal({
        title: this.$t('confirm delete'),
        dangerMode: true,
        buttons: [this.$t('cancel'), this.$t('delete')],
      }).then((value) => {
        if (value) {
          axios.get(url)
          .then(response => {
            //削除
            if (projectengineer == 'engineer') {
              if (onoff == 'on') {
                this.engineer_items_online.splice(this.curr_item.index, 1)
              } else {
                this.engineer_items_offline.splice(this.curr_item.index, 1)
              }
            } else {
              if (onoff == 'on') {
                this.project_items_online.splice(this.curr_item.index, 1)
              } else {
                this.project_items_offline.splice(this.curr_item.index, 1)
              }
            }
            this.curr_item.item = null
            this.curr_item.index = null
          })
          .catch(error => {
            if (error.response.status == 422) {
              if (error.response.data.message){
                swal({
                  icon: "warning",
                  text: error.response.data.message,
                });
              }
            } else {
              swal({
                icon: "error",
                text: error,
              })
            }
          })
        }
      })
    },
    handleDeleteFavorite(index,item) {
      let url = ''
      url = '/api/admin/project_open/delete_favorite?id=' + item.favorites_id
      swal({
        title: this.$t('confirm delete'),
        dangerMode: true,
        buttons: [this.$t('cancel'), this.$t('delete')],
      }).then((value) => {
        if (value) {
          axios.get(url)
          .then(response => {
            this.project_items_favorites.splice(index, 1)
          })
          .catch(error => {
            if (error.response.status == 422) {
              if (error.response.data.message){
                swal({
                  icon: "warning",
                  text: error.response.data.message,
                });
              }
            } else {
              swal({
                icon: "error",
                text: error,
              })
            }
          })
        }
      })
    },
    AddProject() {
      this.$refs.popoverMenu.close()
      // this.$f7router.navigate('/add_engineer/', {props: { add_update_mode: '1' }})
      let options = { add_update_mode: '1' }
      this.$f7router.navigate('/add_project/' + JSON.stringify(options))
    },
    UpdateProject(index,item) {
      // this.$f7router.navigate("/update_engineer/", {props: { add_update_mode: '2', current_row: item}})
      let options = { add_update_mode: '2', project_id: item.id }
      this.$f7router.navigate('/update_project/' + JSON.stringify(options))
    },
    AddEngineer() {
      this.$refs.popoverMenu.close()
      // this.$f7router.navigate('/add_engineer/', {props: { add_update_mode: '1' }})
      let options = { add_update_mode: '1' }
      this.$f7router.navigate('/add_engineer/' + JSON.stringify(options))
    },
    UpdateEngineer(index,item) {
      // this.$f7router.navigate("/update_engineer/", {props: { add_update_mode: '2', current_row: item}})
      let options = { add_update_mode: '2', engineer_id: item.id }
      this.$f7router.navigate('/update_engineer/' + JSON.stringify(options))
    },
  }
}
</script>
