<template>
  <canvas
    id="round_stamp"
    width="65" height="65"
    style="border: 0px solid #666666;">
  </canvas>
</template>

<script>
export default {
  name: 'RoundStamp',
  props: {
    companyName: { type: String, default: '株式会社AAAAAAAA' },
    stampName: { type: String, default: '承認' }
  },
  data:() => ({
    //internalValue: false
  }),
  mounted() {
    //canvas绘制图像的原点是canvas画布的左上角
    var canvas = document.getElementById("round_stamp")
    if (canvas.getContext) {
      var context = canvas.getContext('2d')
      //ここに四角形・円などの図形を描くコードを記述する
      this.drawStamp(canvas, context, this.companyName, this.stampName);
    }
  },
  methods: {
    //绘制圆形印章
    drawStamp(canvas, context, companyName, stampName) {
      //清除画布法一
      context.globalAlpha = 1;
      // context.fillStyle="#ffffff";
      // context.fillRect(0,0,100,100);

      // 绘制印章边框
      var width = canvas.width/2;
      var height = canvas.height/2;
      context.lineWidth = 2;
      context.strokeStyle = "#f00";
      context.beginPath();
      context.arc(width, height, 30, 0, Math.PI * 2);//宽、高、半径
      context.stroke();
      //画五角星
      //create5star(context,width,height,25,"#f00",0);
      //绘制印章名称
      context.font = '9px MS ゴシック';
      context.textBaseline = 'middle';//设置文本的垂直对齐方式
      context.textAlign = 'center';   //设置文本的水平对齐方式
      context.lineWidth = 1;
      context.fillStyle = '#f00';
      context.save();
      context.translate(width, height+4); //平移到此位置,
      context.scale(1, 1.5); //伸缩要先把远点平移到要写字的位置，然后绘制文字
      context.fillText(stampName, 0, 0); //原点已经移动
      context.restore();

      //绘制印章会社名称
      context.translate(width, height); //平移到此位置,
      context.font = '8px MS ゴシック'
      var  count = companyName.length; //字数
      var  angle = 4*Math.PI/(3*(count-1)); //字间角度
      var chars = companyName.split("");
      var c;
      for (var i=0; i < count; i++) {
        c = chars[i]; //需要绘制的字符
      　//绕canvas的画布圆心旋转
        if (i == 0) {
          context.rotate(5*Math.PI/6);
        } else {
          context.rotate(angle);
        }
        context.save();
        context.translate(21, 0); //平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
        context.rotate(Math.PI/2); //旋转90度,让字平行于x轴
        context.scale(1, 1.5); //伸缩画布，实现文字的拉长
        context.fillText(c, 0, 0); //此点为字的中心点
        context.restore();
      }
      // 设置画布为最初的位置为原点，旋转回平衡的原位置，用于清除画布
      context.rotate(-Math.PI/6);
      context.translate(0-canvas.width/2, 0-canvas.height/2);
    },
    //绘制五角星
    /**
    * 创建一个五角星形状. 该五角星的中心坐标为(sx,sy),中心到顶点的距离为radius,rotate=0时一个顶点在对称轴上
    * rotate:绕对称轴旋转rotate弧度
    */
    create5star(context, sx, sy, radius, color, rotato) {
      context.save()
      context.fillStyle = color
      context.translate(sx, sy) //移动坐标原点
      context.rotate(Math.PI+rotato) //旋转
      context.beginPath() //创建路径
      var x = Math.sin(0)
      var y = Math.cos(0)
      var dig = Math.PI/5*4
      for (var i=0; i<5; i++) { //画五角星的五条边
        var x = Math.sin(i*dig)
        var y = Math.cos(i*dig)
        context.lineTo(x*radius, y*radius)
      }
      context.closePath()
      context.stroke()
      context.fill()
      context.restore()
    }
  }
}
</script>
<style>
</style>
