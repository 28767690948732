<template>
  <div class="card">
    <div v-if="title" class="card-header card-title">
      {{ title }}
    </div>

    <div class="card-body">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',

  props: {
    title: { type: String, default: null }
  }
}
</script>
<style>
  .card-title {
    color: #fff;
    background-color: rgb(33, 150, 243);
  }
</style>
