<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-title>{{ $t('add') }}</f7-nav-title>
      <f7-nav-right>
        <f7-link popover-open=".popover-menu">
          <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <!-- top right menu  -->
    <f7-popover ref="popoverMenu" class="popover-menu">
      <f7-list>
        <f7-list-item @click="AddEngineer()" :title="$t('engineer add')">
          <f7-icon slot="media" ios="f7:person_badge_plus_fill" aurora="f7:person_badge_plus_fill" md="material:person_add"></f7-icon>
        </f7-list-item>
        <f7-list-item @click="AddProject()" :title="$t('project add')">
          <f7-icon slot="media" ios="f7:rectangle_stack_fill_badge_plus" aurora="f7:rectangle_stack_fill_badge_plus" md="material:post_add"></f7-icon>
        </f7-list-item>
      </f7-list>
    </f7-popover>

    <f7-block-title>Load page in main view</f7-block-title>
    <f7-list>
      <f7-list-item @click="AddEngineer" :title="$t('engineer add')" view="#main-view"></f7-list-item>
      <f7-list-item link="/manage/" :title="$t('project add')"  view="#main-view"></f7-list-item>
    </f7-list>

    <f7-block-title>Large Buttons</f7-block-title>
    <f7-block>
      <f7-row tag="p">
        <f7-col tag="span">
          <f7-button large raised round color="green" @click="AddEngineer">{{ $t('engineer add') }}</f7-button>
        </f7-col>
        <f7-col tag="span">
          <f7-button large raised round color="green" @click="AddProject">{{ $t('project add') }}</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>

  </f7-page>
</template>

<script>
export default {
  methods: {
    AddEngineer() {
      // console.log('AddEngineer called')
      this.$refs.popoverMenu.close()
      // this.$f7router.navigate('/add_engineer/', {props: { add_update_mode: '1' }})
      let options = { add_update_mode: '1' }
      this.$f7router.navigate('/add_engineer/' + JSON.stringify(options))
    },
    AddProject() {
      // console.log('AddProject called')
      this.$refs.popoverMenu.close()
      // this.$f7router.navigate('/add_engineer/', {props: { add_update_mode: '1' }})
      let options = { add_update_mode: '1' }
      this.$f7router.navigate('/add_project/' + JSON.stringify(options))
    },
  }
}
</script>
