<template>
  <f7-page>
    <f7-navbar :title="$t('service terms')" :back-link="$t('back')"></f7-navbar>
    <f7-card v-for="(item,index) in service_terms_items" v-bind:key="item.id">
      <f7-card-header class="no-border" valign="bottom" v-if="item.header">
        {{ item.header }}
      </f7-card-header>
      <f7-card-content>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_1">{{ item.content_1 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_2">{{ item.content_2 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_3">{{ item.content_3 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_4">{{ item.content_4 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_5">{{ item.content_5 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_6">{{ item.content_6 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_7">{{ item.content_7 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_8">{{ item.content_8 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_9">{{ item.content_9 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_10">{{ item.content_10 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_11">{{ item.content_11 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_12">{{ item.content_12 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_13">{{ item.content_13 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_14">{{ item.content_14 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_15">{{ item.content_15 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_16">{{ item.content_16 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_17">{{ item.content_17 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_18">{{ item.content_18 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_19">{{ item.content_19 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_20">{{ item.content_20 }}</p>
      </f7-card-content>
    </f7-card>
  </f7-page>
</template>

<script>
import axios from 'axios'
import swal from "sweetalert"

export default {
  name: 'ServiceTerms',
  metaInfo() {
    return { title: "利用規約" }
  },
  data() {
      return {
        service_terms_items: [],
      }
  },
  mounted() {
    this.get_service_terms()
  },
  methods: {
    async get_service_terms() {
      await axios.get('/api/admin/setting/get_service_terms')
      .then(response => {
        this.service_terms_items = response.data.service_terms
      })
      .catch(function (error) {
        if (error.response.status == 422) {
          if (error.response.data.message){
            swal({
              icon: "warning",
              text: error.response.data.message,
            })
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
  }
}
</script>
