import Vue from 'vue'
import TablePanel from './TablePanel'
import RoundStamp from './RoundStamp'
import MonthManage from './MonthManage'
import ElCard from './ElCard'
import Card from './Card'
import Child from './Child'
import Button from './Button'
import Checkbox from './Checkbox'
// import SideMenu from './SideMenu'

import { HasError, AlertError, AlertErrors, AlertSuccess } from 'vform'
// Components that are registered globaly.
[
  TablePanel,
  RoundStamp,
  MonthManage,
  ElCard,
  Card,
  Child,
  Button,
  Checkbox,
  // SideMenu,
  HasError,
  AlertError,
  AlertErrors,
  AlertSuccess
].forEach(Component => {
  Vue.component(Component.name, Component)
})
