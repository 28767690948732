import * as types from '../mutation-types'

// state
export const state = {
  engineer_open_sort: '',
  project_open_sort: '',
  manage_sort: ''
}

// getters
export const getters = {
  engineer_open_sort: state => state.engineer_open_sort,
  project_open_sort: state => state.project_open_sort,
  manage_sort: state => state.manage_sort
}

// mutations
export const mutations = {
  [types.UPDATE_ENGINEER_OPEN_SORT] (state, { engineer_open_sort }) {
    state.engineer_open_sort = engineer_open_sort
  },
  [types.UPDATE_PROJECT_OPEN_SORT] (state, { project_open_sort }) {
    state.project_open_sort = project_open_sort
  },
  [types.UPDATE_MANAGE_SORT] (state, { manage_sort }) {
    state.manage_sort = manage_sort
  },
  [types.CLEAR_ENGINEER_OPEN_SORT] (state) {
    state.engineer_open_sort = ''
  },
  [types.CLEAR_PROJECT_OPEN_SORT] (state) {
    state.project_open_sort = ''
  },
  [types.CLEAR_MANAGE_SORT] (state) {
    state.manage_sort = ''
  },
}

// actions
export const actions = {
  updateEngineerOpenSort ({ commit }, payload) {
    commit(types.UPDATE_ENGINEER_OPEN_SORT, payload)
  },
  updateProjectOpenSort ({ commit }, payload) {
    commit(types.UPDATE_PROJECT_OPEN_SORT, payload)
  },
  updateManageSort ({ commit }, payload) {
    commit(types.UPDATE_MANAGE_SORT, payload)
  },
  clearEngineerOpenSort ({ commit }, payload) {
    commit(types.CLEAR_ENGINEER_OPEN_SORT, payload)
  },
  clearProjectOpenSort ({ commit }, payload) {
    commit(types.CLEAR_PROJECT_OPEN_SORT, payload)
  },
  clearManageSort ({ commit }, payload) {
    commit(types.CLEAR_MANAGE_SORT, payload)
  },
}
