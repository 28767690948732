<template>
  <f7-page>
    <f7-navbar :title="$t('privacy policy')" :back-link="$t('back')"></f7-navbar>
    <f7-card v-for="(item,index) in privacy_policy_items" v-bind:key="item.id">
      <f7-card-header class="no-border" valign="bottom" v-if="item.header">
        {{ item.header }}
      </f7-card-header>
      <f7-card-content>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_1">{{ item.content_1 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_2">{{ item.content_2 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_3">{{ item.content_3 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_4">{{ item.content_4 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_5">{{ item.content_5 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_6">{{ item.content_6 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_7">{{ item.content_7 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_8">{{ item.content_8 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_9">{{ item.content_9 }}</p>
        <p style="white-space:pre-line; word-wrap:break-word;" v-if="item.content_10">{{ item.content_10 }}</p>
      </f7-card-content>
    </f7-card>
  </f7-page>
</template>

<script>
import axios from 'axios'
import swal from "sweetalert"

export default {
  name: 'PrivacyPolicy',
  metaInfo() {
    return { title: "プライバシーポリシー" }
  },
  data() {
      return {
        privacy_policy_items: [],
      }
  },
  mounted() {
    this.get_privacy_policy()
  },
  methods: {
    async get_privacy_policy() {
      await axios.get('/api/admin/setting/get_privacy_policy')
      .then(response => {
        this.privacy_policy_items = response.data.privacy_policy
      })
      .catch(function (error) {
        if (error.response.status == 422) {
          if (error.response.data.message){
            swal({
              icon: "warning",
              text: error.response.data.message,
            })
          }
        } else {
          swal({
            icon: "error",
            text: error,
          })
        }
      })
    },
  }
}
</script>
