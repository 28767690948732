// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

// menu.js
export const UPDATE_COLLAPSE = 'UPDATE_COLLAPSE'

// engSearchCond.js
export const UPDATE_ENGINEER_CONDITIONS = 'UPDATE_ENGINEER_CONDITIONS'
export const CLEAR_ENGINEER_CONDITIONS = 'CLEAR_ENGINEER_CONDITIONS'

// monthSwitch.js
export const UPDATE_MONTH_PROJECT_OPEN = 'UPDATE_MONTH_PROJECT_OPEN'
export const CLEAR_MONTH_PROJECT_OPEN = 'CLEAR_MONTH_PROJECT_OPEN'
export const UPDATE_MONTH_ENGINEER_OPEN = 'UPDATE_MONTH_ENGINEER_OPEN'
export const CLEAR_MONTH_ENGINEER_OPEN = 'CLEAR_MONTH_ENGINEER_OPEN'
export const UPDATE_MONTH_MANAGE = 'UPDATE_MONTH_MANAGE'
export const CLEAR_MONTH_MANAGE = 'CLEAR_MONTH_MANAGE'

// sortManage.js
export const UPDATE_ENGINEER_OPEN_SORT = 'UPDATE_ENGINEER_OPEN_SORT'
export const UPDATE_PROJECT_OPEN_SORT = 'UPDATE_PROJECT_OPEN_SORT'
export const UPDATE_MANAGE_SORT = 'UPDATE_MANAGE_SORT'
export const CLEAR_ENGINEER_OPEN_SORT = 'CLEAR_ENGINEER_OPEN_SORT'
export const CLEAR_PROJECT_OPEN_SORT = 'CLEAR_PROJECT_OPEN_SORT'
export const CLEAR_MANAGE_SORT = 'CLEAR_MANAGE_SORT'

// pushed.js
export const ADD_NOTICE = 'ADD_NOTICE'
export const ADD_PROJECT = 'ADD_PROJECT'
export const ADD_ENGINEER = 'ADD_ENGINEER'
export const ADD_COMPANY = 'ADD_COMPANY'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const VERSION_UP = 'VERSION_UP'
export const ADD_COMPLAINT = 'ADD_COMPLAINT'

// transfered.js
export const ADD_FAVORITES_ID = 'ADD_FAVORITES_ID'
