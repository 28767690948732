import * as types from '../mutation-types'

// state
export const state = {
  current_month_project_open: '',
  current_month_engineer_open: '',
  current_month_manage: ''
}

// getters
export const getters = {
  current_month_project_open: state => state.current_month_project_open,
  current_month_engineer_open: state => state.current_month_engineer_open,
  current_month_manage: state => state.current_month_manage
}

// mutations
export const mutations = {
  [types.UPDATE_MONTH_PROJECT_OPEN] (state, { current_month_project_open }) {
    state.current_month_project_open = current_month_project_open
  },
  [types.CLEAR_MONTH_PROJECT_OPEN] (state) {
    state.current_month_project_open = ''
  },
  [types.UPDATE_MONTH_ENGINEER_OPEN] (state, { current_month_engineer_open }) {
    state.current_month_engineer_open = current_month_engineer_open
  },
  [types.CLEAR_MONTH_ENGINEER_OPEN] (state) {
    state.current_month_engineer_open = ''
  },
  [types.UPDATE_MONTH_MANAGE] (state, { current_month_manage }) {
    state.current_month_manage = current_month_manage
  },
  [types.CLEAR_MONTH_MANAGE] (state) {
    state.current_month_manage = ''
  },
}

// actions
export const actions = {
  updateMonthProjectOpen ({ commit }, payload) {
    commit(types.UPDATE_MONTH_PROJECT_OPEN, payload)
  },
  clearMonthProjectOpen ({ commit }, payload) {
    commit(types.CLEAR_MONTH_PROJECT_OPEN, payload)
  },
  updateMonthEngineerOpen ({ commit }, payload) {
    commit(types.UPDATE_MONTH_ENGINEER_OPEN, payload)
  },
  clearMonthEngineerOpen ({ commit }, payload) {
    commit(types.CLEAR_MONTH_ENGINEER_OPEN, payload)
  },
  updateMonthManage ({ commit }, payload) {
    commit(types.UPDATE_MONTH_MANAGE, payload)
  },
  clearMonthManage ({ commit }, payload) {
    commit(types.CLEAR_MONTH_MANAGE, payload)
  },
}
